import { queryOptions } from '@tanstack/react-query';

import { AUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

interface FamilyListOptionsProps {
  isLogin: boolean;
}

export const familyQueryOptions = {
  list: ({ isLogin }: FamilyListOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.familyList],
      queryFn: AUTH_API.getFamilyList,
      select: ({ context }) => {
        const { wello_member_family_list, total_policy_count } = context ?? {};

        return {
          familyList: wello_member_family_list,
          totalPolicyCount: total_policy_count,
        };
      },
      enabled: isLogin,
    }),
};
