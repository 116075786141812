import { queryOptions } from '@tanstack/react-query';

import { AUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

interface InquiryOptionsProps {
  id: number;
}

interface InquiryListOptionsProps {
  page?: number;
  size?: number;
}

export const inquiryQueryOptions = {
  detail: ({ id }: InquiryOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.inquiry, id],
      queryFn: () => AUTH_API.getInquiry({ inquiryId: id }),
    }),
  list: ({ page = 1, size = 999 }: InquiryListOptionsProps = {}) =>
    queryOptions({
      queryKey: [queryKeys.inquiryList, page, size],
      queryFn: () => AUTH_API.getInquiryList({ page, size }),
    }),
};
