import type { MyInfo } from '@/stores/AuthStore';

import { queryOptions } from '@tanstack/react-query';
import {
  createError,
  createUuid,
  isEnumValue,
} from '@wello-client/common/utils';

import { AUTH_API, MEMBER_JOIN_STATUS, UNAUTH_API } from '@/api';

export const authQueryOptionsKeys = {
  myInfo: createUuid(),
  kbLoginTypeList: createUuid(),
};

interface MyInfoProps {
  accessToken: string | null;
}

interface KbLoginTypeListProps {
  externalMemberId: string | null;
}

export const authQueryOptions = {
  checkAccessTokenStatus: (token: string | null | undefined) =>
    queryOptions({
      queryKey: [token],
      enabled: !!token,
      refetchInterval: 30_000,
      retryDelay: 1_000,
      queryFn: () => {
        if (!token) throw createError({ return_message: '토큰이 없습니다.' });

        return UNAUTH_API.checkAccessTokenStatus({
          requestBody: {
            access_token: token,
          },
        });
      },
      select: (data) => data.context?.usable_access_token,
    }),
  myInfo: ({ accessToken }: MyInfoProps) =>
    queryOptions({
      queryKey: [authQueryOptionsKeys.myInfo],
      queryFn: AUTH_API.getMyInfo,
      enabled: !!accessToken,
      select: ({ context }) => {
        const { member_status_cd, save_status_cd } = context ?? {};
        if (
          !(save_status_cd && isEnumValue(MEMBER_JOIN_STATUS, save_status_cd))
        )
          throw new Error('save_status_cd가 없거나, 유효하지 않은 값입니다.');

        const memberJoinStatus =
          save_status_cd === MEMBER_JOIN_STATUS.COMPLETE &&
          member_status_cd === MEMBER_JOIN_STATUS.NO_PHONE
            ? MEMBER_JOIN_STATUS.NO_PHONE
            : save_status_cd;

        return {
          nickName: context?.nick_name,
          email: context?.email,
          id: context?.member_oauth_id,
          age: context?.age,
          gender: context?.gender,
          isVerification: context?.verification_completed_yn,
          memberPolicyCount: context?.member_policy_cnt,
          profileImage: context?.profile_image,
          birthDate: context?.birth_date,
          memberCode: context?.member_code,
          region: context?.region,
          subRegion: context?.sub_region,
          codeRegion: context?.code_region,
          codeSubRegion: context?.code_sub_region,
          cellPhone: context?.cell_phone,
          memberJoinStatus,
          memberOauth: context?.member_oauth_id,
          marketingMessageReceiverUpdateAt:
            context?.marketing_message_receiver_update_at,
          salary: context?.salary,
          code_gender: context?.code_gender,
          notificationOptions: {
            best_contents_receive_yn: context?.best_contents_receive_yn,
            hometown_news_receive_yn: context?.hometown_news_receive_yn,
            like_comment_yn: context?.like_comment_yn,
            policy_apply_message_receive_yn:
              context?.policy_apply_message_receive_yn,
            marketing_message_receive_yn: context?.marketing_message_receive_yn,
            popular_policy_receive_yn: context?.popular_policy_receive_yn,
            reply_comment_yn: context?.reply_comment_yn,
            report_comment_yn: context?.report_comment_yn,
            suggest_policy_receive_yn: context?.suggest_policy_receive_yn,
          },
        } satisfies MyInfo;
      },
    }),

  kbLoginTypeList: ({ externalMemberId }: KbLoginTypeListProps) =>
    queryOptions({
      queryKey: [authQueryOptionsKeys.kbLoginTypeList, externalMemberId],
      queryFn: () => {
        if (!externalMemberId)
          throw createError({
            return_message: 'kbId가 없습니다.',
          });

        return UNAUTH_API.getKbMemberLoginType({
          externalMemberId,
        });
      },
      enabled: !!externalMemberId,
      select: (data) => data.context?.login_type_list,
    }),
};
