import { queryOptions } from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

interface SituationOptionsProps {
  isLogin: boolean | undefined;
  situationId: number;
}

export const situationQueryOptions = {
  list: ({ isLogin, situationId }: SituationOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.situationPolicyList, situationId, isLogin],
      queryFn: () => {
        return isLogin
          ? AUTH_API.getSituationPolicyList({ situationId })
          : UNAUTH_API.getSituationPolicyList({ situationId });
      },
      enabled: isLogin !== undefined,
      select: ({ context }) => {
        return {
          id: context?.situation?.situation_id,
          title: context?.situation?.name,
          contents: context?.situation?.description ?? '',
          thumbnail: context?.situation?.file_url,
          policyCount: context?.situation?.policy_count ?? 0,
          policyList:
            context?.situation_list?.map((policy) => ({
              id: policy.id,
              idIdx: policy.meta_policy_id_idx,
              policyName: policy.policy_name,
              dday: policy.dday,
              agencyLogo: policy.agency_logo,
              agency: policy.agency,
              isThumbUp: policy.like_yn,
              dueDateEnd: policy.expiration_date,
              supportBenefit: policy.support_benefit,
              provisionTypeListStr: policy.provision_type_str_list,
              likeCount: policy.like_count,
              commentCount: policy.comment_count,
              isWishlist: policy.wishlist_yn,
            })) ?? [],
        };
      },
    }),
};
