import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

interface HometownNewsDetailOptionsProps {
  id: number;
  isLogin?: boolean;
}

interface HometownNewsDetailListOptionsProps {
  codeRegion: string;
  codeSubRegion: string;
  hometownNewsIdIdx: string;
  isLogin: boolean;
}

interface HometownNewsListOptionsProps {
  keyword?: string;
  codeRegion: string;
  codeSubRegion: string;
  isLogin: boolean | undefined;
  hometownNewsSort?: 'LATEST' | 'POPULARITY';
}

export const hometownNewsQueryOptions = {
  list: ({
    keyword,
    isLogin,
    codeRegion,
    codeSubRegion,
    hometownNewsSort = 'LATEST',
  }: HometownNewsListOptionsProps) => {
    const INITIAL_PAGE = 1;
    const PAGE_SIZE = 5;

    return infiniteQueryOptions({
      queryKey: [
        queryKeys.hometownNewsList,
        keyword,
        codeRegion,
        codeSubRegion,
        hometownNewsSort,
        INITIAL_PAGE,
        PAGE_SIZE,
        isLogin,
      ],
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          codeRegion,
          codeSubRegion,
          page: pageParam,
          size: PAGE_SIZE,
          searchKeyword: keyword,
          hometownNewsSort,
        };

        return isLogin
          ? AUTH_API.getHometownList(params)
          : UNAUTH_API.getHometownList(params);
      },
      enabled: isLogin !== undefined,
      select: (data) => ({
        pages: data.pages.map(({ context }) => ({
          contents: context?.contents?.map((news) => {
            return {
              id: news.hometown_news_id,
              idIdx: news.hometown_news_id_idx,
              title: news.hometown_news_name,
              thumbnail: news.thumbnail,
              thumbUpCount: news.like_count,
              commentCount: news.comment_count,
              isThumbUp: news.like_yn,
              isBookmarked: news.wishlist_yn,
              dateText: news.published_at,
            };
          }),
          totalCount: context?.total_count,
        })),
        pageParams: data.pageParams,
      }),
      getNextPageParam: (lastPage) => {
        if (lastPage.context?.total_count && lastPage.context.page) {
          const nextPage = lastPage.context.page + 1;
          const maxPage = Math.ceil(lastPage.context.total_count / PAGE_SIZE);

          return nextPage <= maxPage ? nextPage : undefined;
        }
      },
      initialPageParam: INITIAL_PAGE,
    });
  },
  detailList: ({
    hometownNewsIdIdx,
    isLogin,
    codeRegion,
    codeSubRegion,
  }: HometownNewsDetailListOptionsProps) =>
    queryOptions({
      queryKey: [
        queryKeys.hometownNewsList,
        codeRegion,
        codeSubRegion,
        hometownNewsIdIdx,
      ],
      queryFn: () =>
        AUTH_API.getHometownNewsDetail({
          hometownNewsIdIdx,
        }),
      enabled: isLogin,
    }),
  feedback: ({ id, isLogin }: HometownNewsDetailOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.hometownNewsFeedback, id, isLogin],
      queryFn: () => {
        const params = { hometownNewsId: id };

        return isLogin
          ? AUTH_API.getHometownNewsFeedback(params)
          : UNAUTH_API.getHometownNewsFeedback(params);
      },
      enabled: isLogin !== undefined,
      select: ({ context }) => {
        if (!context) throw Error('hometown news context not valid');

        return {
          isThumbUp: context.like_yn,
          thumbUpCount: context.like_count,
          isBookmarked: context.wishlist_yn,
        };
      },
    }),
};
