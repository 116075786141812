import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';
import { createUuid } from '@wello-client/common/utils';

import { AUTH_API, UNAUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

const policyInfoQueryOptionsKeys = {
  list: createUuid(),
};

interface PolicyInfoFeedbackOptionsProps {
  id: number;
  isLogin: boolean | undefined;
}

interface PolicyInfoListOptionsProps {
  isLogin: boolean | undefined;
  keyword?: string;
  policySort?: 'LATEST' | 'POPULARITY';
  codeProvisionType?: string;
}

export const policyInfoQueryOptions = {
  list: ({
    isLogin,
    policySort = 'LATEST',
    keyword,
    codeProvisionType,
  }: PolicyInfoListOptionsProps) => {
    const INITIAL_PAGE = 1;
    const PAGE_SIZE = 5;

    return infiniteQueryOptions({
      queryKey: [
        policyInfoQueryOptionsKeys.list,
        keyword,
        policySort,
        codeProvisionType,
        INITIAL_PAGE,
        PAGE_SIZE,
        isLogin,
      ],
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          page: pageParam,
          size: PAGE_SIZE,
          searchKeyword: keyword,
          bestContentsSort: policySort,
          codeProvisionType: codeProvisionType,
        };

        return isLogin
          ? AUTH_API.getPolicyInfoDetail(params)
          : UNAUTH_API.getPolicyInfoList(params);
      },
      enabled: isLogin !== undefined,
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count: totalCount } =
          lastPage.context ?? {};

        if (!totalCount || !currentPage) return;

        const nextPage = currentPage + 1;
        const totalPageCount = Math.ceil(totalCount / PAGE_SIZE);

        if (nextPage <= totalPageCount) return nextPage;
      },
      select: (data) =>
        data.pages.map((page) => ({
          policyInfoList: page.context?.contents?.map(
            ({ provision_type, ...content }) => {
              const provisionTypeList: string[] = [];

              for (const key in provision_type) {
                const value = provision_type[key];
                if (value) provisionTypeList.push(value);
              }

              return { ...content, provisionTypeList };
            },
          ),
          totalCount: page.context?.total_count,
        })),
      initialPageParam: INITIAL_PAGE,
    });
  },
  feedback: ({ id, isLogin }: PolicyInfoFeedbackOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.policyInfoFeedback, id, isLogin],
      queryFn: () => {
        const params = { bestContentsId: id };

        return isLogin
          ? AUTH_API.getPolicyInfoFeedback(params)
          : UNAUTH_API.getPolicyInfoFeedback(params);
      },
      enabled: isLogin !== undefined,
      select: ({ context }) => {
        if (!context) throw Error('policy info context not valid');

        return {
          isThumbUp: context.like_yn,
          thumbUpCount: context.like_count,
          isBookmarked: context.wishlist_yn,
        };
      },
    }),
};
