import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

interface PolicyOptionsProps {
  id: number;
  isLogin: boolean | undefined;
}

interface PolicyDetailProps {
  id: string;
  isLogin: boolean | undefined;
}
interface SearchOptionsProps {
  codeRegion?: string;
  codeSubRegion?: string;
  keyword?: string;
  isLogin?: boolean | undefined;
  descAgeList?: string[];
  codeProvisionTypeList?: string[];
  policySort?: 'LATEST' | 'POPULARITY';
}

export const policyQueryOptions = {
  feedback: ({ id, isLogin }: PolicyOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.policyFeedback, id, isLogin],
      queryFn: () => {
        const params = { policyId: id };

        return isLogin
          ? AUTH_API.getPolicyFeedback(params)
          : UNAUTH_API.getPolicyFeedback(params);
      },
      enabled: isLogin !== undefined,
      select: ({ context }) => ({
        isThumbUp: context?.like_yn,
        thumbUpCount: context?.like_count,
        isBookmarked: context?.wishlist_yn,
      }),
    }),
  count: () =>
    queryOptions({
      queryKey: [queryKeys.policyCount],
      queryFn: UNAUTH_API.getPolicyCount,
    }),
  pick: ({ isLogin, id }: PolicyOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.pickPolicyList, id, isLogin],
      queryFn: () => {
        return isLogin
          ? AUTH_API.getPickPolicyList({ welloPickId: id })
          : UNAUTH_API.getPickPolicyList({ welloPickId: id });
      },
      enabled: isLogin !== undefined,
      select: ({ context }) => ({
        id: context?.wello_pick?.wello_pick_id,
        title: context?.wello_pick?.title ?? '',
        contents: context?.wello_pick?.contents ?? '',
        thumbnail: context?.wello_pick?.file_url ?? '',
        policyCount: context?.wello_pick?.policy_count ?? 0,
        policyList:
          context?.wello_pick_policy_list?.map((metaPolicy) => ({
            id: metaPolicy.id,
            idIdx: metaPolicy.meta_policy_id_idx,
            isThumbUp: metaPolicy.like_yn,
            policyName: metaPolicy.policy_name,
            dday: metaPolicy.dday,
            agencyLogo: metaPolicy.agency_logo,
            agency: metaPolicy.agency,
            dueDateEnd: metaPolicy.expiration_date,
            supportBenefit: metaPolicy.support_benefit,
            provisionTypeListStr: metaPolicy.provision_type_str_list,
            likeCount: metaPolicy.like_count,
            commentCount: metaPolicy.comment_count,
            isWishlist: metaPolicy.wishlist_yn,
          })) ?? [],
      }),
    }),
  detail: ({ id, isLogin }: PolicyDetailProps) =>
    queryOptions({
      queryKey: [queryKeys.policyDetail, id],
      queryFn: () => {
        const params = { metaPolicyIdIdx: id };

        return AUTH_API.getPolicyAuthDetail(params);
      },
      enabled: isLogin !== undefined && isLogin,
      select: ({ context }) => ({
        likeYn: context?.like_yn,
        dislikeYn: context?.dislike_yn,
        wishlistYn: context?.wishlist_yn,
      }),
    }),
  search: ({
    isLogin,
    keyword,
    codeRegion,
    codeSubRegion,
    descAgeList,
    codeProvisionTypeList,
    policySort = 'LATEST',
  }: SearchOptionsProps) => {
    const INITIAL_PAGE = 1;
    const PAGE_SIZE = 5;

    return infiniteQueryOptions({
      queryKey: [
        queryKeys.recommendPolicyList,
        keyword,
        codeRegion,
        codeSubRegion,
        descAgeList ?? [],
        codeProvisionTypeList ?? [],
        policySort,
        INITIAL_PAGE,
        PAGE_SIZE,
        isLogin,
      ],
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          searchKeyword: keyword,
          codeRegion,
          codeSubRegion,
          page: pageParam,
          size: PAGE_SIZE,
          policySort,
          descAgeList,
          codeProvisionTypeList,
        };

        return isLogin
          ? AUTH_API.getSearchRecommendPolicyList(params)
          : UNAUTH_API.getSearchRecommendPolicyList(params);
      },
      enabled: isLogin !== undefined,
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count: totalCount } =
          lastPage.context ?? {};

        if (!totalCount || !currentPage) return;

        const nextPage = currentPage + 1;
        const totalPageCount = Math.ceil(totalCount / PAGE_SIZE);

        if (nextPage <= totalPageCount) return nextPage;
      },
      initialPageParam: INITIAL_PAGE,
      placeholderData: keepPreviousData,
    });
  },
};
