import { queryOptions } from '@tanstack/react-query';

import { AUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

interface ProfileScrapOptionsProps {
  isLogin: boolean;
}

export const profileScrapQueryOptions = {
  policy: ({ isLogin }: ProfileScrapOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.profileScrap, 'policyWishlist'],
      queryFn: AUTH_API.getPolicyWishlist,
      enabled: isLogin,
      select: ({ context }) => context,
    }),
  hometownNews: ({ isLogin }: ProfileScrapOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.profileScrap, 'hometownNewsWishlist'],
      queryFn: AUTH_API.getHometownNewsWishlist,
      enabled: isLogin,
      select: ({ context }) => context,
    }),
  bestContents: ({ isLogin }: ProfileScrapOptionsProps) =>
    queryOptions({
      queryKey: [queryKeys.profileScrap, 'bestContentsWishlist'],
      queryFn: AUTH_API.getBestContentsWishlist,
      enabled: isLogin,
      select: ({ context }) => context,
    }),
};
